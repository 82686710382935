exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-construction-materials-js": () => import("./../../../src/pages/construction_materials.js" /* webpackChunkName: "component---src-pages-construction-materials-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-and-beverages-js": () => import("./../../../src/pages/food_and_beverages.js" /* webpackChunkName: "component---src-pages-food-and-beverages-js" */),
  "component---src-pages-hotel-supplies-js": () => import("./../../../src/pages/hotel_supplies.js" /* webpackChunkName: "component---src-pages-hotel-supplies-js" */),
  "component---src-pages-household-items-js": () => import("./../../../src/pages/household_items.js" /* webpackChunkName: "component---src-pages-household-items-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lighting-and-luminaries-js": () => import("./../../../src/pages/lighting_and_luminaries.js" /* webpackChunkName: "component---src-pages-lighting-and-luminaries-js" */),
  "component---src-pages-others-js": () => import("./../../../src/pages/others.js" /* webpackChunkName: "component---src-pages-others-js" */),
  "component---src-pages-outdoor-furnitures-js": () => import("./../../../src/pages/outdoor_furnitures.js" /* webpackChunkName: "component---src-pages-outdoor-furnitures-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

